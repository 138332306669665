import * as React from "react";
import Layout from "../components/Layout";
import "../styles/HomePage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img from "../images/404 Error-amico.png";

const NotFoundPage = () => {
  return (
    <Layout title="Accueil">
      <div className="home">
        <div className="presentation">
          <div>
            <h1>Oops... Page not found</h1>
            <h2>The page you are searching for doesn't exist</h2>
          </div>
          <div className="backbutton">
            <a href="/">
              <button>
                <FontAwesomeIcon icon={"home"} />
                Go back to home page
              </button>
            </a>
          </div>
        </div>
        <img src={img} />
      </div>
    </Layout>
  );
};

export default NotFoundPage;
